import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of, take, tap } from 'rxjs';

import { DotLottieFileSrc } from '../models/dotlottie-file-src';

/** Service for getting and caching dotLottie animations. */
@Injectable({ providedIn: 'root' })
export class DotLottieCacheService {

	private readonly http = inject(HttpClient);

	private readonly cache = new Map<string, ArrayBuffer>();

	/**
	 * Gets dotLottie data by src of the file.
	 * First tries to get the value from cache.
	 * If the value is not cached yet, makes http request for the data, caches it and returns response.
	 * @param src Src of the file.
	 */
	public getBySrc(src: DotLottieFileSrc): Observable<ArrayBuffer> {
		const cacheValue = this.cache.get(src);

		if (cacheValue != null) {
			return of(cacheValue);
		}

		return this.http.get(src, { responseType: 'arraybuffer' }).pipe(
			tap(response => this.cache.set(src, response)),
		);
	}

	/**
	 * Loads file by src and stores it in cache.
	 * @param src Src of the file.
	 */
	public cacheBySrc(src: DotLottieFileSrc): void {
		this.http.get(src, { responseType: 'arraybuffer' }).pipe(
			take(1),
		)
			.subscribe(response => this.cache.set(src, response));
	}
}
