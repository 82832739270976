import { AfterRenderPhase, CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, afterNextRender, effect, input, signal, viewChild } from '@angular/core';
import { Config, DotLottie } from '@lottiefiles/dotlottie-web';

DotLottie.setWasmUrl('/assets/dotlottie-player.wasm');

/** Dot lottie player. */
@Component({
	selector: 'wndrc-dotlottie-player',
	standalone: true,
	imports: [],
	templateUrl: './dotlottie-player.component.html',
	styleUrl: './dotlottie-player.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DotLottiePlayerComponent implements OnDestroy {

	/** Data of dotLottie file. */
	public readonly data = input<Config['data']>();

	/** Src of the dotLottie file. */
	public readonly src = input<Config['src']>();

	/** Whether need to autoplay. */
	public readonly autoplay = input(true);

	/** Whether need to loop. */
	public readonly loop = input(true);

	/**
	 * Animation segment.
	 * Accepts an array of two numbers, where the first number is the start frame and the second number is the end frame.
	 */
	public readonly segment = input<Config['segment']>();

	private readonly canvas = viewChild.required<ElementRef<HTMLCanvasElement>>('canvas');

	private readonly canvasBoundingRect = signal<DOMRect | null>(null);

	private readonly resizeObserver = new ResizeObserver(() => {
		this.canvasBoundingRect.set(this.canvas().nativeElement.getBoundingClientRect());
	});

	public constructor() {

		// Under hood dotLottie resizes canvas based on its DOMRect width and height,
		// and later uses canvas width and height to create ImageData for it.
		// Before initializing the animation we need to make sure that these values are not zeros,
		// otherwise it will throw error when trying to initialize ImageData.
		afterNextRender(() => {
			this.resizeObserver.observe(this.canvas().nativeElement);
		}, { phase: AfterRenderPhase.Write });

		effect(onCleanUp => {
			const canvasBoundingRect = this.canvasBoundingRect();

			if (canvasBoundingRect == null || canvasBoundingRect.width === 0 || canvasBoundingRect.height === 0) {
				return;
			}

			if (this.data() == null && this.src() == null) {
				return;
			}

			const dotLottie = new DotLottie({
				canvas: this.canvas().nativeElement,
				autoplay: this.autoplay(),
				loop: this.loop(),
				src: this.src(),
				data: this.data(),
				segment: this.segment(),
			});

			onCleanUp(() => dotLottie.destroy());
		});
	}

	/** @inheritdoc */
	public ngOnDestroy(): void {
		this.resizeObserver.disconnect();
	}
}
