import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { Config } from '@lottiefiles/dotlottie-web';
import { AsyncPipe } from '@angular/common';
import { DotLottieCacheService } from '@wndr/common/core/services/dotlottie-cache.service';
import { DotLottieFileSrc } from '@wndr/common/core/models/dotlottie-file-src';

import { DotLottiePlayerComponent } from '../dotlottie-player/dotlottie-player.component';

/** Loader theme. */
type LoaderTheme = 'dark' | 'light';

type LoaderMode = 'overlay' | 'inline';

/** Loader spinner component. */
@Component({
	selector: 'wndrc-loader',
	standalone: true,
	templateUrl: './loader.component.html',
	styleUrl: './loader.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [DotLottiePlayerComponent, AsyncPipe],
})
export class LoaderComponent {

	private readonly dotLottieCacheService = inject(DotLottieCacheService);

	/** DotLottie loader data. */
	protected readonly loaderData$ = this.dotLottieCacheService.getBySrc(DotLottieFileSrc.Loader);

	/** Loading label.. */
	public readonly label = input<string, string>(
		'Loading...',
		{ transform: label => label ? label : 'Loading...' },
	);

	/** Loader theme. */
	public readonly theme = input<LoaderTheme>('dark');

	/** Loader mode. */
	public readonly mode = input<LoaderMode>('overlay');

	/** Animation segment to cut off the beginning of the animation. */
	protected readonly animationSegment: Config['segment'] = [20, 72];
}
