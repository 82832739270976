<div
	class="content"
	[class.content_light]="theme() === 'light'"
	[class.content_dark]="theme() === 'dark'"
	[class.content_inline]="mode() === 'inline'"
>
	<wndrc-dotlottie-player
		[data]="(loaderData$ | async) ?? undefined"
		class="loader"
		[segment]="animationSegment"
	/>
	@if (label()) {
		<span class="label">{{ label() }}</span>
	}
</div>
